import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import { CONTAINER_TYPES } from "../../infrastructure/ioc/container-types";
import ServiceInterface from "@/vue/service/service-interface";
import DfProduct from "@/vue/domain/product/df-product";
import Utils from "@/vue/infrastructure/utils/helpers";
import DfShoppingProduct from "@/vue/domain/shopping-product/df-shopping-product";
import DfPromotion from "@/vue/domain/promotion/df-promotion";
import moment from "moment";
import DfStore from "@/vue/domain/store/df-store";
import DfBrand from "@/vue/domain/brand/df-brand";

@Component({
  filters: {
    percentual: (value: string) => {
      const data: string = new Intl.NumberFormat(navigator.language, {
        style: "decimal",
        minimumFractionDigits: 0,
      }).format(parseFloat((parseFloat(value) || 0).toFixed(0)));
      return `-${data}%`;
    },
    decimal: (value: string) => {
      return new Intl.NumberFormat(navigator.language, {
        style: "decimal",
        minimumFractionDigits: 2,
      }).format(parseFloat((parseFloat(value) || 0).toFixed(2)));
    },
    price: (value: string, unit: string) => {
      unit = unit ? `${unit}` : "";
      const result: string = new Intl.NumberFormat(navigator.language, {
        style: "currency",
        currency: "EUR",
      }).format(parseFloat(value) || 0);
      if (unit == "") {
        return `${result}`;
      } else if (unit == "KG" || unit == "L") {
        return `${unit} ${result}`;
      } else {
        return `${unit}`;
      }
    },
    dateFormat(value: Date | string): string {
      return value != null ? moment(value, Utils.ISO_8601_STRING_PATTERN).format(Utils.DATE_STRING_PATTER) : "";
    },
  },
})
export default class DfCardProductFeauturedComponent extends Vue {
  @Inject(CONTAINER_TYPES.ServiceInterface)
  private service!: ServiceInterface;

  @Prop({ type: Object, default: () => ({}) })
  product!: DfProduct;
  @Prop({ type: String, default: null })
  promotionAlias: string;
  @Prop({ type: Boolean, default: false })
  brandMissingPreview: boolean;
  @Prop({ type: String, default: null })
  context: string;
  @Prop({ type: String, default: "digital_flyer" })
  origin: string;

  get brand(): DfBrand {
    return this.$store.getters.brand;
  }

  get brandLogoImageUrl(): string {
    return Utils.getPropertyImageUrl(this.brand, Utils.PROPERTY_LOGO, `${Utils.getPublicPath()}/assets/logo.png`);
  }

  get currentPromotion(): DfPromotion {
    return this.$store.getters.currentPromotion;
  }

  get currentStore(): DfStore {
    return this.$store.getters.currentStore;
  }

  get isSelected(): boolean {
    return this.shoppingList.findIndex((product: DfShoppingProduct) => product.description === this.productDescription) > -1;
  }

  get shoppingList(): Array<DfShoppingProduct> {
    return this.$store.getters.shoppingList;
  }

  set shoppingList(shoppingList: Array<DfShoppingProduct>) {
    this.$store.dispatch("setShoppingList", shoppingList);
  }

  get discount(): number {
    return Utils.getPropertyValue(this.product, Utils.PROPERTY_PRODUCT_DISCOUNT, "STRING");
  }

  get discountPercentual(): number {
    return Utils.getPropertyValue(this.product, Utils.PROPERTY_PRODUCT_DISCOUNT_PERCENTUAL, "NUMBER");
  }

  get initialPrice(): number {
    return Utils.getPropertyValue(this.product, Utils.PROPERTY_PRODUCT_INITIAL_PRICE, "NUMBER");
  }

  get finalPrice(): number {
    return Utils.getPropertyValue(this.product, Utils.PROPERTY_PRODUCT_FINAL_PRICE, "NUMBER");
  }

  get finalPriceForUnit(): number {
    return Utils.getPropertyValue(this.product, Utils.PROPERTY_PRODUCT_FINAL_PRICE_FOR_UNIT, "NUMBER");
  }

  get unitMisure(): string {
    const value = Utils.getPropertyValue(this.product, Utils.PROPERTY_PRODUCT_UNIT, "STRING");
    return value == "HG" ? this.$t("df-measure-unit.hg.label").toString() : value;
  }

  get previewUrl(): string {
    return Utils.getPropertyImageUrl(this.product, Utils.PROPERTY_PRODUCT_PREVIEW, null);
  }

  get brandLogosUrl(): Array<string> {
    return Utils.getPropertyImagesUrl(this.product, Utils.PROPERTY_PRODUCT_BRAND_LOGOS);
  }

  get propertyLogosUrl(): Array<string> {
    return Utils.getPropertyImagesUrl(this.product, Utils.PROPERTY_PRODUCT_PROPERTY_LOGOS);
  }

  get productDateFrom(): string {
    return Utils.getPropertyValue(this.product, Utils.PROPERTY_PRODUCT_DATE_FROM, "DATE");
  }

  get productDateTo(): string {
    return Utils.getPropertyValue(this.product, Utils.PROPERTY_PRODUCT_DATE_TO, "DATE");
  }

  get startDate(): string {
    const startDate: string = this.productDateFrom ? this.productDateFrom : this.currentPromotion.startDate;
    return startDate ? this.$options.filters!.dateFormat(startDate) : null;
  }

  get endDate(): string {
    const endDate: string = this.productDateTo ? this.productDateTo : this.currentPromotion.endDate;
    return endDate ? this.$options.filters!.dateFormat(endDate) : null;
  }

  get missingPreviewUrl(): string {
    return this.brandMissingPreview ? this.brandLogoImageUrl : this.previewUrl;
  }

  get productDescription(): string {
    const forceMaiuscole: boolean = Utils.getPropertyValue(this.brand, Utils.PROPERTY_BRAND_FORCE_UPPERCASE_PRODUCT_DESCRIPTION, "BOOLEAN", false);
    const description: string = Utils.getPropertyValue(this.product, Utils.PROPERTY_PRODUCT_DESCRIPTION, "STRING");
    const longDescription: string = Utils.getPropertyValue(this.product, Utils.PROPERTY_PRODUCT_LONG_DESCRIPTION, "TEXT");

    const productDescription: string = description || longDescription || this.product.description || "";
    return forceMaiuscole ? productDescription.toUpperCase() : productDescription;
  }

  private toggleSelection() {
    const index: number = this.shoppingList.findIndex((product: DfShoppingProduct) => product.description === this.productDescription);
    if (index === -1) {
      this.shoppingList = [new DfShoppingProduct(this.productDescription, false), ...this.shoppingList];
      Utils.setAnaliticsTraker({
        event: "add_to_wishlist",
        product: this.productDescription.replace(/[\n\r]/g, " "),
        origin: this.origin,
      });
    } else {
      this.shoppingList = this.shoppingList.slice(0, index).concat(this.shoppingList.slice(index + 1, this.shoppingList.length));
    }
  }

  private setAnaliticsTraker() {
    Utils.setAnaliticsTraker({
      event: "click_product",
      store: this.currentStore.name,
      promo_name: this.currentPromotion.description,
      product: this.productDescription.replace(/[\n\r]/g, " "),
      context: this.context,
      origin: this.origin,
    });
  }
}
